@import url('https://fonts.googleapis.com/css2?family=Teko:wght@300;600&display=swap');

html {
	font-family: "Helvetica Neue", Helvetica, sans-serif;
	background: black;
	color: white;
	-webkit-font-smoothing: antialiased;
	overflow: none;
	box-sizing: border-box;
}

html, body {
	margin: 0;
	padding: 0;
}

html, body, #app {
	height: 100%;
	min-height: 100%;
}

.container {
	height: 100%;
	justify-items: center;
	align-items: center;
}

.alpha,
.bravo {
	font-family: 'Teko', sans-serif;
}

.alpha {
	font-weight: 600;
	line-height: 0.85;
}
.bravo {
	font-weight: 300;
	text-transform: uppercase;
	letter-spacing: 0.1em;
}

.subhead {
	width: 80vw;
	margin: 0 auto 1em;
	font-size: 1.1rem;
	color: rgb(220,225,230,0.7);
}

header {
	padding-top: 3rem;
	text-align: center;
}

.logo {
	width: 3rem;
	height: 3rem;
	color: rgb(255,99,0);
}

h1 {
	margin-bottom: 0;
	font-size: 3rem;
	text-align: center;
}

.btn {
	font-size: 2em;
	padding: 0.5em 1em;
	background: white;
	color: black;
	border: none;
	font-family: inherit;
	font-weight: bold;
	border-radius: 0.1em;
}

.btn--electric {
	background-color: rgb(0,156,227);
	color: white;
}

.settingsBtn {
	position: absolute;
	display: block;
	height: 3rem;
	width: 3rem;
	top: 0;
	right: 0;
	font-size: 3em;
	line-height: 0;
	color: white;
	background: transparent;
	border: none;

	&--hidden {
		opacity: 0;
	}
}

#settings {
	position: absolute;
	padding: 3rem;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0,0,0,0.8);
	z-index: 100;

	fieldset {
		padding: 2rem;
		background: #111;
	}

	legend {
		padding: 0 1rem;
		font-size: 1.5em;
		font-weight: bold;
	}

	input[type="text"],
	input[type="number"] {
		width: 5em;
	}
}

.s-locker {
	padding: 1em 0;
	margin: 0;
	border-top: 1px solid rgb(255,255,255,0.2);

	&__combo {
		margin-left: 2em;
		font-family: monospace;
	}
}

#view-start, #view-entry {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 70vh;
}

#view-form {
	padding: 0 2rem 2rem;

	h2 {
		text-align: center;
	}

	.rules {
		padding: 1rem 2rem;
		margin-bottom: 2rem;
		background: rgba(255,255,255,0.1);
		height: 35vh;
		overflow-y: scroll;
		font-size: 0.85rem;
		line-height: 1.5;
	}

	.wufoo-wrapper {
		padding: 1.5rem;
		background-color: white;
	}
	iframe {
		width: 800vw;
		height: 35vh;
		min-height: 360px;
		overflow-y: scroll;
	}
}

.combo {
	margin-top: 3rem;
	margin-bottom: 5rem;
	display: grid;
	grid-template-columns: auto auto;
	grid-template-rows: auto auto;
	background-color: rgb(0,156,227);
	padding: 2rem 2rem 2.5rem;
	grid-column-gap: 3rem;
	grid-row-gap: 1rem;
	border-radius: 0.5em;

	transform: rotate(-3deg);

	.label-locker {
		grid-row-start: 1;
		grid-column-start: 1;
	}
	.label-combo {
		grid-row-start: 1;
		grid-column-start: 2;
	}
	.value-locker {
		grid-row-start: 2;
		grid-column-start: 1;
	}
	.value-combo {
		grid-row-start: 2;
		grid-column-start: 2;
	}

	.label-locker, .label-combo {
		text-transform: uppercase;
		font-family: Teko;
		font-weight: 300;
		letter-spacing: 0.15em;
		line-height: 1;
		font-size: 2.25rem
	}

	.value-locker, .value-combo {
		font-weight: bold;
		letter-spacing: 0.1em;
		font-size: 12rem;
		line-height: 0.7;
	}

	.label-locker, .value-locker {
		color: saturate(darken(rgb(0,156,227), 30%), 50%);
		// color: rgb(0,0,0,0.8);
	}
}

.rules {
	table {
		margin: 2em auto;
		border-collapse: collapse;
	}
	th, td {
		padding: 0.25em 0.5em;
		border-bottom: 1px solid rgb(255,255,255,0.5);
	}
	th {
		border-bottom: 2px solid rgb(255,255,255,0.75);
	}
	a {
		color: white;
		text-decoration: underline;
	}
	h1 {
		font-size: 2em;
		line-height: 1.1;
	}
}

#reset {
	position: absolute;
	bottom: 3rem;
	right: 3rem;
	background: transparent;
	color: rgb(255,255,255,0.25);
	border: none;

	svg {
		height: 60px;
		width: 60px;
	}
}
